import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";

import { AuthenticationService } from "src/app/services/Security/authentication.service";
import { PersistenceService } from "@src/app/services/Utilities/data.persistence.service";
import { LocalStorageList } from "@src/app/helpers/enums/enums.keys";
import { ReportEnvironment } from "@src/app/models/Report/report-setting";
import { SettingsService } from "@src/app/services/Utilities/settings.service";
import { ThemeService } from "@src/app/services/Utilities/theme.service";

@Component({
    selector: "app-redirect-component",
    templateUrl: "./redirect-component.component.html",
    styleUrls: ["./redirect-component.component.scss"],
    standalone: false
})
// apunta a v1
export class RedirectComponentComponent implements OnInit, AfterViewInit {
  public url = "";
  host = "";
  sub: Subscription;
  a_field: string = "";
  @ViewChild("form") postForm: ElementRef;
  ready = false;
  //private source = interval(3000);

  @Input() inputHost: string = "";
  themeSwitcher = false;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private persistenceService: PersistenceService,
    private settingsService: SettingsService,
    themeService: ThemeService
  ) {
    this.a_field = this.authenticationService.getToken();
    this.host = settingsService.getBaseUrlV1;
    this.themeSwitcher = themeService.isDarkModeMode();

  }

  ngAfterViewInit(): void {
    this.sub = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.createUrl(e.url);
      }
    });
  }

  ngOnInit() {
    this.createUrl(this.router.url);
  }

  createUrl(goTo: string) {

    //sobre escribimos la ruta porque fue enviada
    if(this.inputHost){
      goTo = this.inputHost;
    }

     
    const hostNew = this.persistenceService.getData(
      LocalStorageList.servidorV1
    );
    var env = this.settingsService.getBaseUrlReportViewer;
    console.log(`ReportViewer: ${env}`)
    if (env === ReportEnvironment.Production) {
      if (hostNew !== "null" && hostNew !== undefined && hostNew !== null && hostNew !== 'undefined') {
        this.host = hostNew;
        console.log(`UrlV1: ${hostNew}`)
      }
    }


    this.url = `${this.host}/instituciones/?origen=1&theme=${this.themeSwitcher}&time=${new Date().getTime()}#${goTo}`;
    //this.url="http://testv1.saludplusweb.com/pruebas/ejemplos"
    console.log(this.url);
    setTimeout(() => {
      this.postForm.nativeElement.submit();
    }, 500);

    // console.warn(this.url);
  }

  /*
    ngOnDestroy() {
      if (this.sub) { this.sub.unsubscribe(); }
    }*/
}
